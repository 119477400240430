$(document).on("focusout",".inline-edit-field",function(e){
  var attributeName = $(this).attr("name")
  var mainDataKey = $(this).attr("data-parent-node")
  $.ajax({
    type: 'POST',
    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
    data: {[mainDataKey]: {[attributeName]: $(this).text()}},
    url: $(this).attr("data-url")
  });
});

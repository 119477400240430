$(document).on('click', ".favourite-annotation", function (event) {
  event.stopImmediatePropagation();
  var divId = $(this).attr("id");
  $.ajax({
    type: 'POST',
    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
    url: $(this).attr("data-url"),
    success: function(){
      $("#"+divId).toggleClass("fa-heart-o");
      $("#"+divId).toggleClass("fa-heart");
      $("#"+divId).toggleClass("text-primary");
      $("#"+divId).toggleClass("text-danger");
    }
  });
});

$(document).on('click', ".toggle-note", function (e) {
  divId = $(this).attr("href");
  $(divId).removeClass("hidden");
  $(divId).find( "p" ).focus();
  e.preventDefault();
});

function assignTag(data, elementId){
  $.ajax({
    type: 'POST',
    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
    data: data,
    url: $("#"+elementId).attr("data-url")
  });
}

function fetchTagResults(request, response, search_term){
  $.ajax({
    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
    url: $(".tag-autocomplete").attr("data-url"),
    type: "GET",
    dataType: "json",
    data: { term: search_term },
    success: function (data) {
      if (!data.length) {
        var result = [{ label: "No tags found, enter to add new", value: search_term }];
        response(result);
      }
      else {
        response($.map(data, function (item) {
            return { id: item.id, value: item.value };
        }))
      }
    }
 })
}

$(document).on("focus.autocomplete",".autocomplete-tag-field",function(e){
  $(this).autocomplete({
    source: function (request, response) {
      var searchTerm = request.term;
      fetchTagResults(request, response, searchTerm);
    },
    select: function( event, ui ) {
      var elementId = $(this).attr('id');
      assignTag({tag_id: ui.item.id}, elementId);
      $(this).val('');
      return false;
    },
  })
});

$(document).on("keydown",".autocomplete-tag-field",function(e){
  var elementId = $(this).attr('id');
  if (e.keyCode === 13){
    assignTag({tag_name: $(this).val()}, elementId);
    $(this).val('');
    $(this).blur(); 
  }
});


$(document).on('click', ".toggle-tag", function (e) {
  divId = $(this).attr("data-id");
  $(divId).removeClass("hidden");
  $(divId).find( "input" ).focus();
  e.preventDefault(); 
});

$(document).on('focusout', ".tag-autocomplete", function (event) {
  $(this).addClass("hidden");
})

$(document).on('click', ".tweetbtn", function (e) {
  e.preventDefault();
  window.open(
    this.getAttribute("href"),
    "twitterwindow", 
    "height=450, width=550, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0"
  );
});
